<template>
  <div eagle-form-item="textarea">
    <v-textarea
      dense
      outlined
      :readonly="readonly"
      :disabled="disabled"
      :maxlength="maxlength"
      :error="!!error"
      :error-messages="error"
      :rules="vuetifyRules"
      :autofocus="focus"
      :counter="!disabled && !readonly"
      v-model="data"
    ></v-textarea>
  </div>
</template>

<script>
import formTextType from '@/components/form/formItemTypes/formTextType'
export default {
  mixins: [formTextType],
  computed: {
    maxlength() {
      return this.formDataItem.maxlength || 150
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>